import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const CannabisEducation = () => {
 const { slug = 'dispensaries' } = useParams()
 const [pageContent, setPageContent] = useState([])

 // CONVERT STRING TO TITLE CASE
 function titleCase(str) {
  return str
   .toLowerCase()
   .split(' ')
   .map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1)
   })
   .join(' ')
 }

 // GET REQUEST FOR PAGE DESCRIPTION
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/sections?slug=${slug}`,
   )
   setPageContent(await response.json())
  } catch (e) {
   console.log(e)
  }
 }

 useEffect(() => {
  getData()
 }, [slug])

 return (
  <React.Fragment>
   {pageContent?.data?.map((val) => {
    if (val.status_id === 3) {
     return (
      <div key={val.id} className="sectionWrap">
       <p style={{ fontSize: '20px', textAlign: 'center' }}>
        Content temporary unavailable
       </p>
      </div>
     )
    }
    return (
     <React.Fragment key={val.id}>
      <Helmet>
       <title>{val.seo_title}</title>
       <meta name="description" content={val.seo_description} />
      </Helmet>
      <SecondaryHeader page_title={val.title} />
      <div className="sectionWrap">
       <div className="sectionContainer">
        <div
         className={`pageContent explore ${slug} ql-editor`}
         dangerouslySetInnerHTML={{ __html: val.description }}
         key={val.id}
        ></div>
       </div>
      </div>
     </React.Fragment>
    )
   })}
  </React.Fragment>
 )
}

export default CannabisEducation
