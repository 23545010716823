import React, { useEffect, useState } from 'react'

const NetworkDispensaryMapList = ({ listDispensary }) => {
 const [singleStates, setSingleStates] = useState()

 useEffect(() => {
  if (listDispensary) {
   const uniqueStates = [
    ...new Set(listDispensary?.data.map((item) => item.state)),
   ]
   setSingleStates(uniqueStates)
  }
 }, [listDispensary])

 const editTitle = (state, title) => {
  if (state === 'District of Columbia') {
   const findIndex = title.indexOf('-')
   return title.slice(findIndex + 1)
  }
  const splitString = title.split(',')[0]
  const findIndex = title.indexOf('-')

  const sliceString = splitString.slice(findIndex + 1)
  return sliceString.trim()
 }

 const filterDispensary = (dispensary) => {
  return listDispensary?.data.filter((item) => item.state === dispensary)
 }

 return (
  <div style={{ marginLeft: '50px' }}>
   {singleStates
    ?.sort((a, b) => a.localeCompare(b))
    .map((dispensary) => (
     <React.Fragment key={dispensary}>
      <h3 style={{ color: '#1f4e79', marginTop: '50px' }}>{dispensary}</h3>
      <div
       style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit,minmax(350px, 1fr))',
        maxWidth: '1200px',
       }}
      >
       {filterDispensary(dispensary)
        ?.sort((a, b) => a.title.localeCompare(b.title))
        .map((el) => (
         <div key={el.id}>
          <p style={{ margin: 0 }}>
           <a
            style={{ color: '#0563c1', textDecoration: 'underline' }}
            href={el.link}
            target="_blank"
           >
            {editTitle(el.state, el.title)}
           </a>
          </p>
          {el.description && (
           <div>
            <p style={{ margin: 0 }}>{el.description?.split('.')[0]}</p>
            <p>{el.description?.split('.')[1]}</p>
           </div>
          )}
         </div>
        ))}
      </div>
     </React.Fragment>
    ))}
  </div>
 )
}
export default NetworkDispensaryMapList
