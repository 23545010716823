import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const Members = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Members</title>{' '}
   </Helmet>
   <SecondaryHeader page_title="Members" />
   <div className="sectionWrap">
    <div className="sectionContainer">
     <div className="member_benefits">
      <div className="intro">
       <h5>Membership</h5>
       <p>
        Bennabis Health is the first healthcare company in the US offering a{' '}
        <strong>membership program</strong> for medical cannabis education and
        savings! If you could benefit from medical cannabis and don't have a
        card yet, it's time to learn if medical cannabis is right for you. See
        if you have a condition that qualified you for a cannabis card in your
        state{' '}
        <Link
         to="education/getting-started#bookmark01"
         target="_blank"
         className="hyperlink"
        >
         here
        </Link>
        . If you already have a medical cannabis card in Delaware, Maryland,
        Massachusetts, New Jersey, New Mexico, New York or Washington DC, start
        saving now!
       </p>
       <p>
        Bennabis Health is transforming the way medical cannabis patients
        understand, access and pay for their medical cannabis. We are a team of
        patients, advocates, educators, medical and healthcare professionals (we
        even have a former US Surgeon General on our team!) on the forefront of
        patient-centric medicinal cannabis and education, devoted to improving
        access and reducing costs of medical cannabis through member discount
        programs.
       </p>
       <p>
        Bennabis Health understands that cannabis as medicine may be new to you
        and the entire process can be overwhelming. We strive to make
        understanding medical cannabis a comfortable and empowering experience
        for you, without fear of repercussions or judgment.
       </p>
       <p>
        <strong>Bennabis Health has 2 tiers of Membership:</strong>
       </p>
      </div>
      <div className="membership_includes">
       <h6>
        Basic Membership: <i>Introductory rate of $29/year!</i>
       </h6>
       <ul>
        <li>No medical cannabis card needed to join</li>
        <li>
         Original Reliable Medical Cannabis Educational Content, created by our
         team of healthcare experts, including a Former US Surgeon General!
        </li>
        <li>Direct access to our team of experts</li>
        <li>Webinars **coming soon**</li>
        <li>Cannabis Support groups **coming soon**</li>
        <li>Cannabis Education and cooking classes **coming soon**</li>
       </ul>
       <h6>
        Premium Membership: <i>Introductory rate of $98/year!</i>
       </h6>
       <ul>
        <li>
         DE, MD, MA, NJ, NM, NY and DC Medical cannabis card required to join
        </li>
        <li>Includes all of the above benefits of a Basic Membership PLUS:</li>
        <li>
         <strong>
          A 15% discount on your medical cannabis at our{' '}
          <Link to="network-dispensary" className="hyperlink">
           Network Dispensaries
          </Link>
          , all day, every day!
         </strong>{' '}
         <i>*Exclusions may apply.</i>
        </li>
       </ul>
       <p>
        Enroll and pay online via credit card - Visa, Mastercard, American
        Express or Discover accepted.
       </p>
       <p>
        <Link to="/enroll" className="switch_signup">
         {' '}
         <em>Click here to become a member of Bennabis Health.</em>
        </Link>
       </p>
      </div>

      <blockquote className="bordered">
       <p className="blockquote_text">
        "I reached out to Bennabis Health because I had a NJ state card but had
        to let it expire because I couldn’t afford the medicine any longer.
        Bennabis Health referred me to a physician so I could reapply for my
        card; I then enrolled in Bennabis Health, it was super easy, and when I
        got my state card in the mail I went to a network dispensary and got my
        medicine at a discount that I would not have qualified for without my
        Bennabis Health membership! I feel so much better now that I got my
        medicine. I would never have been able to do this without Bennabis
        Health!"
       </p>
       <p className="blockquote_credit">Member 425</p>
      </blockquote>

      <div className="page_footer">
       <div className="switch_faq">
        <Link to="/faqs"> Members FAQs</Link>
       </div>
       <div className="switch_contact">
        Additional Questions? <Link to="/contact">Please Contact Us Here</Link>
       </div>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Members
