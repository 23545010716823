import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { useLocation } from 'react-router-dom'

const Policies = () => {
 const { slug } = useParams()
 const [PoliciesContent, setPoliciesContent] = useState([])
 const { pathname, hash, key } = useLocation()

 const bottomRef = useRef(null)

 //  GET REQUEST FOR PAGE DESCRIPTION
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/policies?slug=${slug}&status_id=1`,
   )
   setPoliciesContent(await response.json())
  } catch (e) {
   console.log(e)
  }
 }

 useEffect(() => {
  getData()
 }, [slug])

 useEffect(() => {
  // JUMP TO BOTTOM OF THE PAGE
  if (hash !== '') {
   setTimeout(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
     element.scrollIntoView({
      behavior: 'smooth',
     })
    }
   }, 0)
  }
 }, [PoliciesContent])

 return (
  <React.Fragment>
   {PoliciesContent?.data?.map((val) => {
    return (
     <React.Fragment key={val.id}>
      <Helmet>
       <title>{val.seo_title}</title>
       <meta name="description" content={val.seo_description} />
      </Helmet>
      <div className="sectionWrap">
       <div className="sectionContainer">
        <div className={`policiesContent ${slug}`}>
         <div
          className="pageContent"
          dangerouslySetInnerHTML={{ __html: val.description }}
          key={val.id}
         ></div>
        </div>
       </div>
      </div>
      <div ref={bottomRef} />
     </React.Fragment>
    )
   })}
  </React.Fragment>
 )
}

export default Policies
