import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Enroll = () => {
 const features = [
  {
   title: '15% Product Discount at Bennabis Health Network Dispensaries',
   basic: 'No',
   premimum: 'Yes',
  },
  {
   title: 'Online Medical Cannabis Educational Content',
   basic: 'Yes',
   premimum: 'Yes',
  },
  {
   title: 'Direct Access to Our Team of Experts',
   basic: 'Yes',
   premimum: 'Yes',
  },
  {
   title: 'Webinars - <span>Coming Soon<span>',
   basic: 'Yes',
   premimum: 'Yes',
  },
  {
   title: 'Support Groups - <span>Coming Soon<span>',
   basic: 'Yes',
   premimum: 'Yes',
  },
  {
   title: 'Educational and Cooking Classes - <span>Coming Soon<span>',
   basic: 'Yes',
   premimum: 'Yes',
  },
 ]

 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Membership Plan</title>{' '}
   </Helmet>
   <div
    className="sectionWrap enrollmentPlans"
    style={{
     backgroundImage: `url(${process.env.PUBLIC_URL + 'graphic-01.png'})`,
    }}
   >
    <div className="sectionContainer">
     <div className="plansWrap">
      <div className="singIn" style={{ marginLeft: 0 }}>
       <a
        rel="noreferrer"
        href={process.env.REACT_APP_MEMBERS_APP}
        target="_blank"
       >
        Already A Member ? Sign In
       </a>
      </div>
      <div className="linkSignIn">
       <p>
        If you enrolled through group benefits,{' '}
        <a
         href={process.env.REACT_APP_HEALTH_PLAN_ENROLL_CONFIRM}
         target="_blank"
         rel="noreferrer"
        >
         Activate your account now.
        </a>
       </p>
      </div>
      <div className="planHeader">
       <div className="item">
        <img src={require('../../assets/images/leaf.svg').default} alt="" />
       </div>
       <div className="item basic">
        <div className="plan">
         <strong>Basic Plan</strong>
         <span className="price">$29</span>
         <span className="text">
          Introductory Rate <br /> Annual Subscription
         </span>
         <small className="text01">
          Medical Cannabis Card <br /> Not Required
         </small>
         <span>
          <Link to="/enroll/basic-plan" className="enroll_now">
           ENROLL NOW
          </Link>
         </span>
        </div>
        <ul>
         <li className="heading">Benefits Include:</li>
         <li>Online Medical Cannabis Educational Content</li>
         <li>Direct Access to Our Team of Experts</li>
         <li>Webinars **coming soon**</li>
         <li>Support Groups **coming soon**</li>
         <li>Educational and Cooking Classes **coming soon**</li>
        </ul>
       </div>
       <div className="item premimum">
        <div className="membershipShild">
         <img
          src={require('../../assets/images/premium-membership.png')}
          alt=""
         />
        </div>
        <div className="plan">
         <strong>Premium Plan</strong>
         <span className="price">$98</span>
         <span className="text">
          Introductory Rate <br /> Annual Subscription
         </span>
         <small className="text01">
          DE, MD, MA, NJ, NM, NY and DC Medical Cannabis Card Required
         </small>
         <span>
          <Link to="/enroll/premium-plan" className="enroll_now">
           ENROLL NOW
          </Link>
         </span>
        </div>
        <ul>
         <li className="heading">Benefits Include:</li>
         <li>Includes all of the above benefits of a Basic Membership PLUS:</li>
         <li>
          <strong>
           A 15% discount on your medical cannabis at our Network Dispensaries,
           all day, every day! *Exclusions may apply.
          </strong>
         </li>
        </ul>
       </div>
      </div>
      <div className="bodyContainer">
       <div className="section featuresWrap">
        {features.map((data, index) => {
         return (
          <React.Fragment key={index}>
           <div className="features">
            <div
             className="item title"
             dangerouslySetInnerHTML={{ __html: data.title }}
            ></div>
            <div className="item">
             {data.basic === 'Yes' ? (
              <img
               src={require('../../assets/images/features-include.svg').default}
               alt=""
              />
             ) : (
              <img
               src={
                require('../../assets/images/features-notinclude.svg').default
               }
               alt=""
              />
             )}
            </div>
            <div className="item">
             {data.premimum === 'Yes' ? (
              <img
               src={require('../../assets/images/features-include.svg').default}
               alt=""
              />
             ) : (
              <img
               src={
                require('../../assets/images/features-notinclude.svg').default
               }
               alt=""
              />
             )}
            </div>
           </div>
          </React.Fragment>
         )
        })}
       </div>
       <div className="section include">
        <small>
         Select our Premium Plan to receive a 15% discount on your cannabis
         medicine at our Network Dispensaries
        </small>
       </div>
      </div>
      <div className="linkSignIn" style={{ marginTop: '15px' }}>
       <p>
        If you enrolled through group benefits,{' '}
        <a
         href={process.env.REACT_APP_HEALTH_PLAN_ENROLL_CONFIRM}
         target="_blank"
         rel="noreferrer"
        >
         Activate your account now.
        </a>
       </p>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Enroll
