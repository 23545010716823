import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'
import { Avatar } from '@mui/material'

const AboutUs = () => {
 const [teams, setTeams] = useState([])

 // GET REQUEST FOR TEAM MEMBER
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/teams?status_id=1`,
   )
   const data = await response.json()
   setTeams(data.data)
  } catch (e) {
   console.log(e)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 return (
  <React.Fragment>
   <Helmet>
    <title>
     About Us | Bennabis Health: Medical Cannabis Discounts & Education
    </title>
    <meta
     name="description"
     content="Bennabis Health was launched to be the first program to provide medical marijuana users with coverage. Read more, and meet the Bennabis Health team, here."
    />
   </Helmet>
   <SecondaryHeader page_title="About Us" />
   <div className="sectionWrap">
    <div className="sectionContainer">
     <div className="company_profile">
      <p style={{ fontSize: '16px', color: '#082F58', fontWeight: '700' }}>
       Bennabis Health is a healthcare company transforming access to medical
       cannabis by making it understandable and affordable through a national
       system that integrates medical cannabis into traditional therapeutic
       healthcare options.
      </p>
      <p style={{ fontSize: '16px', color: '#082F58', fontWeight: '700' }}>
       Driven by an experienced team, Bennabis Health delivers discounts through
       a multistate dispensary network, comprehensive patient education, and a
       roadmap for the medicinal cannabis journey. Our first-to-market system is
       available to individuals as well as employer groups looking to offer a
       unique employee benefit.
      </p>
      <p>
       One of the founding members of <strong>Bennabis Health</strong> knows
       first hand, as a patient diagnosed with Relapsing-Remitting Multiple
       Sclerosis in 2013, how vital medical cannabis can be for patients. When
       Anne was told of the MS drug regimen she would be required to use and the
       monitoring she would undergo to check whether that regimen was otherwise
       harming her physiology, she opted to forego that regimen and use cannabis
       instead. From then until now she continues to do very well medically and
       her physician, who opposed her decision initially, now tells her she made
       a good decision. But that decision came with a cost since Anne’s health
       insurance did not cover medical cannabis due to the federal government’s
       continued classification of cannabis as a Schedule I drug.
      </p>
      <p>
       <strong>Bennabis Health</strong> is the solution to this problem for Anne
       and the many medical cannabis users like her. Bennabis Health is
       dedicated to filling the holes in the health insurance industry for
       medical cannabis patients and providing coverage for those patients left
       behind due to federal government restrictions.
      </p>
      <p>
       Inspired by Anne’s story, Don Parisi, a long-time dispensary board
       member, former President of a health plan, and former New Jersey Deputy
       Attorney General for the Commissioner of Insurance, launched Bennabis
       Health to be the first program to provide medical marijuana users with
       coverage. Don and Anne have surrounded themselves with other
       professionals who share the same vision, including people with cannabis
       industry, health insurance, claims, legal and financial experience. Visit
       our{' '}
       <Link to="/faqs" className="hyperlink">
        FAQ
       </Link>{' '}
       page to see our commonly asked questions.
      </p>
     </div>
     <div className="team_wrapper">
      <div className="section_title">
       <h3>Meet Our Team</h3>
       <p>
        Each of the members of the management team has extensive experience in
        either health insurance or cannabis industry operations.
       </p>
      </div>
      <div className="teamWrap">
       {teams
        .sort((a, b) => a.order_by - b.order_by)
        .map((val) => {
         // let thumbnail = getThumbnail(val.thumbnail);

         return (
          <div className="team" key={val.id}>
           <div className="imgBox">
            <Link to={`/about-us/team/${val.id}`}>
             <img
              src={
               val.thumbnail !== null
                ? val.thumbnail
                : require('../assets/images/avatar.png')
              }
              alt={val.name}
             />
            </Link>
           </div>
           <div className="infoBox">
            <h6> {val.name}</h6>
            <span>{val.designation}</span>
           </div>
          </div>
         )
        })}
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

// function getThumbnail(thumbnail) {
//   let thumb = thumbnail?.includes('http')
//     ? thumbnail
//     : `${process.env.REACT_APP_ADMIN_URL}assets/upload/${thumbnail}`;
//   if (thumbnail === null || thumbnail === undefined || thumbnail === '') {
//     thumb = require('../assets/images/avatar.png');
//   }
//   return thumb;
// }

export default AboutUs
