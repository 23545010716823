import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getThumbnail } from './ViewBlog';
import {Avatar} from "@mui/material";

const Team = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);

  // GET FAQs CATEGORY LISTING
  const profileDetail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/teams/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': '',
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setProfile(data.data);
      } else {
        throw new Error(data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    profileDetail();
  }, []);

  return (
    <React.Fragment>
      {profile && (
        <React.Fragment>
          <Helmet>
            <title>{profile.seo_title}</title>
          </Helmet>
          <div className="breadCrumbWrap">
            <div className="breadCrumb">
              <ul>
                <li>
                  <Link to="/">
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/">About Us</Link>
                </li>
                <li>
                  <Link to="/about-us/">Team</Link>
                </li>
                <li>
                  <Link to="">{profile.name}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="sectionWrap">
            <div className="sectionContainer">
              <div className="profilDetail">
                <div className="headerSection">
                  <div className="profileImage">
                    <div className="overlayer">&nbsp;</div>
                    {/*<img*/}
                    {/*  src={getThumbnail(profile.thumbnail)}*/}
                    {/*  alt={profile.name}*/}
                    {/*></img>*/}
                    <img src={profile.thumbnail !== null ? profile.thumbnail : require('../assets/images/avatar.png')} alt={profile.name} />
                    {/*<Avatar src={profile.thumbnail} sx={{width:"100%", height:"100%" }} />*/}
                  </div>
                  <div className="intro">
                    <h5>
                      {profile.name} <small>{profile.designation}</small>
                    </h5>
                    <div className="introduction">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: profile.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Team;
