import React from 'react'
import { Switch, Route } from 'react-router-dom'

// IMPORT COMPONENTS
import Home from '../template/Home'
import Members from '../template/Members'
import NetworkDispensary from '../template/network-dispensary/NetworkDispensary'
import AboutUs from '../template/AboutUs'
import Faqs from '../template/Faqs'
import Podcasts from '../template/Podcasts'
import Affiliates from '../template/Affiliates'
import NewsLetter from '../template/NewsLetter'
import Contact from '../template/Contact'
import PatientQuestionnaire from '../template/PatientQuestionnaire'
import Physician from '../template/Physician'
import SearchWeb from '../template/SearchWeb'
import Promos from '../template/Promos'
import Media from '../template/Media'
import WorkWithUs from '../template/WorkWithUs'
import Policies from '../template/Policies'
import Topic from '../template/Topic'
import Team from '../template/Team'

import PressReleases from '../template/PressReleases'
import ViewPost from '../template/ViewPost'

import Blog from '../template/Blog'
import ViewBlog from '../template/ViewBlog'

// ENROLLMENT
import Enroll from '../template/enrollment/Enroll'
import BasicEnrollment from '../template/enrollment/BasicEnrollment'
import PremimumEnrollment from '../template/enrollment/PremimumEnrollment'
import SuccessEnrollment from '../template/enrollment/SuccessEnrollment'

// CANNABIS EDUCATION
import GettingStart from '../template/cannabis-education/GettingStart'
import Ready from '../template/cannabis-education/Ready'
import Curious from '../template/cannabis-education/Curious'
import Experienced from '../template/cannabis-education/Experienced'
import ConditionSpecific from '../template/cannabis-education/ConditionSpecific'

// OTHER
import Error404 from '../template/Error404'
import UnderMaintenance from '../template/UnderMaintenance'
import UnderConstruction from '../template/UnderConstruction'
import WorkersComp from '../template/cannabis-education/WorkersComp'
import MembershipCard from '../template/MembershipCard'

const LoadTemplate = () => {
 return (
  <React.Fragment>
   <div className="sourceTemplate">
    <Switch>
     <Route exact path="/" component={Home} />
     <Route exact path="/members" component={Members} />
     <Route exact path="/network-dispensary" component={NetworkDispensary} />
     <Route exact path="/work-with-us" component={WorkWithUs} />
     <Route exact path="/about-us" component={AboutUs} />
     <Route exact path="/faqs" component={Faqs} />
     {/*<Route exact path='/testimonials' component={Testimonials} />*/}
     <Route exact path="/podcasts" component={Podcasts} />
     <Route exact path="/affiliates" component={Affiliates} />
     <Route exact path="/search" component={SearchWeb} />
     <Route exact path="/newsletter" component={NewsLetter} />
     <Route exact path="/promos" component={Promos} />
     <Route exact path="/register-basic-plan" component={Enroll} />
     <Route exact path="/contact" component={Contact} />
     <Route
      exact
      path="/patient-questionnaire"
      component={PatientQuestionnaire}
     />
     {/*<Route exact path="/physician" component={Physician} />*/}
     <Route exact path="/membership-card" component={MembershipCard} />
     <Route exact path="/workers-compensation" component={WorkersComp} />
     {/* DYNAMIC PAGE */}
     <Route path="/faqs/:id" component={Faqs} />
     <Route path="/media/:slug" component={Media} />
     <Route path="/work-with-us/:slug" component={WorkWithUs} />
     <Route path="/policies/:slug" component={Policies} />
     <Route exact path="/about-us/team/:id" component={Team} />
     <Route exact path="/press-releases/" component={PressReleases} />
     <Route exact path="/press-releases/:id" component={ViewPost} />
     <Route exact path="/blog/" component={Blog} />
     <Route exact path="/blog/:id" component={ViewBlog} />
     {/* ENROLLMENT */}
     <Route exact path="/enroll" component={Enroll} />
     <Route exact path="/enroll/basic-plan" component={BasicEnrollment} />
     <Route exact path="/enroll/premium-plan" component={PremimumEnrollment} />
     <Route exact path="/enroll/success" component={SuccessEnrollment} />
     {/* EDUCATION */}
     <Route exact path="/education" component={GettingStart} />
     <Route exact path="/education/getting-started" component={GettingStart} />
     <Route exact path="/education/curious" component={Curious} />
     <Route exact path="/education/ready" component={Ready} />
     <Route exact path="/education/experienced" component={Experienced} />
     <Route
      exact
      path="/education/condition-specific"
      component={ConditionSpecific}
     />
     <Route exact path="/education/:category/:topic" component={Topic} />
     {/* OTHER PAGES */}
     <Route path="/member-portal" component={UnderMaintenance} />
     <Route path="/under-construction" component={UnderConstruction} />
     <Route component={Error404} />
    </Switch>
   </div>
  </React.Fragment>
 )
}

export default LoadTemplate
